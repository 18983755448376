import React from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import ImageGrid from "./imagegrid/ImageGrid";
import { imageConfig } from "./imagegrid/grid.config";

function App() {
  return (
    <div>
      <Header />
      <ImageGrid config={imageConfig} />
      <Footer />
    </div>
  );
}

export default App;
