export interface ImageConfig {
  name: string;
  alt: string;
}

export const imageConfig: ImageConfig[] = [
  {
    name: "richard-bound-P8290893.jpg",
    alt: "whales breaching",
  },
  {
    name: "richard-bound-EE7A7105.jpg",
    alt: "wild ponies",
  },
  {
    name: "R6II4503.jpg",
    alt: "high key two pelicans",
  },
  {
    name: "richard-bound-EE7A6979.jpg",
    alt: "old man of storr",
  },
  {
    name: "richard-bound-R6II4246-Edit.jpg",
    alt: "sea eagle overhead",
  },
  {
    name: "R6II3455.jpg",
    alt: "three pelican sunrise"
  },
  {
    name: "richard-bound-P6100094-Edit.jpg",
    alt: "polar bear with two cubs",
  },
  {
    name: "richard-bound-R6II6360.jpg",
    alt: "puff puff",
  },
  
  {
    name: "richard-bound-DSC07105edited.jpg",
    alt: "gentoo penguin",
  },
  {
    name: "richard-bound-2O6A2152-Edit.jpg",
    alt: "walrus",
  },
  {
    name: "richard-bound-Black-Browed Alabtross.jpg",
    alt: "black browed albatross",
  },
  {
    name: "R6II3589-Enhanced-NR.jpg",
    alt: "mystical pelican",
  },
  {
    name: "richard-bound-DSC03248edited2.jpg",
    alt: "king penguin",
  },
  {
    name: "richard-bound-EE7A4391.jpg",
    alt: "otter",
  },
  {
    name: "richard-bound-DSC04608.jpg",
    alt: "big iceberg",
  },
  {
    name: "richard-bound-R6II8153-Enhanced-NR.jpg",
    alt: "heron",
  },
  {
    name: "richard-bound-2O6A1779.jpg",
    alt: "polar bear cub",
  },
  {
    name: "richard-bound-0Y1A4639.jpg",
    alt: "red kite square",
  },
  {
    name: "richard-bound-R6II4527-Enhanced-NR.jpg",
    alt: "sea eagle wide",
  },
  {
    name: "richard-bound-P6231650-Edit.jpg",
    alt: "ganet diving",
  },
  {
    name: "richard-bound-R6II0134-Enhanced-NR-Edit.jpg",
    alt: "short-eared owl looking back",
  },
  {
    name: "richard-bound-0Q6A1648.jpg",
    alt: "red knot murmeration",
  },
  {
    name: "richard-bound-R6II0985.jpg",
    alt: "grest grested grebe",
  },
  {
    name: "richard-bound-DSC02716.jpg",
    alt: "seal",
  },
  {
    name: "richard-bound-2O6A2384.jpg",
    alt: "polar bear with cub",
  },
  {
    name: "richard-bound-P9030619.jpg",
    alt: "black bear",
  },
  {
    name: "richard-bound-R6II7221-Enhanced-NR.jpg",
    alt: "arctic tern",
  },
  {
    name: "richard-bound-EE7A5079.jpg",
    alt: "sea eagles cross shape",
  },
  {
    name: "richard-bound-R6II1984.jpg",
    alt: "norway senja reflection",
  },
  {
    name: "R6II5264.jpg",
    alt: "pelican flying in the nowhere",
  },
  {
    name: "richard-bound-P6130744.jpg",
    alt: "arctic fox running",
  },
  {
    name: "richard-bound-R6II2023-Enhanced-NR.jpg",
    alt: "kingfisher",
  },
  {
    name: "richard-bound-DSC04235.jpg",
    alt: "baby seal white fur",
  },
  {
    name: "richard-bound-Gentoo Penguins.jpg",
    alt: "gentoo penguins walking",
  },
  {
    name: "R6II8813.jpg",
    alt: "pink pelican (white pelican)",
  },
  {
    name: "richard-bound-DSC09101.jpg",
    alt: "french mountain summer",
  },
  {
    name: "richard-bound-2O6A1263.jpg",
    alt: "polar bear cub",
  },
  {
    name: "richard-bound-P8311332.jpg",
    alt: "tug boat canada",
  },
  {
    name: "R6II2908.jpg",
    alt: "close pelican taking off",
  },
  {
    name: "richard-bound-R6II9755.jpg",
    alt: "marsh harrier",
  },
  {
    name: "richard-bound-R6II1916.jpg",
    alt: "great white egret",
  },
  {
    name: "richard-bound-EE7A5567.jpg",
    alt: "sea eagle fishing",
  },
  {
    name: "richard-bound-EE7A7058.jpg",
    alt: "new forest pig",
  },
  
];
