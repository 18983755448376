import { useEffect, useState } from "react";
import styles from "./GridImage.module.css";

interface IGridImage {
  src: string;
  alt: string;
}

export default function GridImage({ src, alt }: IGridImage) {
  const [size, setInitialSize] = useState({ width: 0, height: 0 });
  const sizeFactor = 240;

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setInitialSize({ width: image.width, height: image.height });
    };
    image.onerror = () => {
      console.warn(`${src} not found`)
    }
  }, [src, setInitialSize]);

  // Dynamic width and flex-grow
  const containerStyles = {
    width: `${(size.width * sizeFactor) / size.height}px`,
    flexGrow: (size.width * sizeFactor) / size.height,
  };

  // Re-adjusts the vertical sizing to maintain aspect ratio - bit magical
  const verticalSizerStyles = {
    paddingBottom: `${(size.height / size.width) * 100}%`,
  };

  return (
    <a href={src} style={containerStyles} className={styles.container}>
      <img src={src} className={styles.gridImg} alt={alt} />
      <i style={verticalSizerStyles} className={styles.spacer}></i>
    </a>
  );
}
