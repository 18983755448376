import { ImageConfig } from "./grid.config";
import GridImage from "./GridImage";
import styles from "./ImageGrid.module.css";

interface IImageGrid {
  config: ImageConfig[];
}

export default function ImageGrid({ config }: IImageGrid) {
  const filepath = `/imagegrid/`;

  const elements = [];

  for (const image of config) {
    const src = `${filepath}${image.name}`;
    elements.push(<GridImage src={src} key={src} alt={image.alt} />);
  }

  return (
    <div className={styles.grid}>
      {elements}

      {/* 
      Have two spacers of smaller size to allow one to roll over to the next line. 
      Two because normally the grid will have 3 images. 
      */}
      <div className={styles.lastRowSpacer}></div>
      <div className={styles.lastRowSpacer}></div>
    </div>
  );
}
